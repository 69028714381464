.App {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.calculatorText {
  font-size: 40px;
  font-weight: bold;
  width: 80%;
  margin: 150px auto 0;
}

.calculator {
  display: grid;
  place-items: end;
  transform: translate(-150px);
}

.screen {
  height: 50px;
  width: 400px;
  color: whitesmoke;
  background-color: darkgray;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  width: 100px;
  height: 70px;
  border: 0.5px solid lightgray;
}

.button.zero {
  width: 200px;
}
