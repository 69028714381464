.homeStyles {
  width: 80%;
  margin: 20px auto;
  padding: 20px;
}

h2 {
  font-size: 40px;
  padding: 10px;
}

p {
  font-size: 18px;
  padding: 10px;
}
