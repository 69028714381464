* {
  font-family: 'Courier New', Courier, monospace;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.NavBarContainer {
  width: 80%;
  margin: 10px auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-right: 20px;
}

.NavBar {
  display: flex;
  gap: 30px;
  align-items: center;
}

.NavBar li {
  list-style: none;
}

.NavBar a {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #555;
  transition: color 0.3s ease;
}

.NavBar a:hover {
  color: #333;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
}
